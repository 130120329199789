import { defineStore } from 'pinia'
import {
  exchangeApi,
  tokenCacheApi,
  tokenPriceService,
  tokenStaticFactory
} from '@shared/Service'
import { TokenStatic } from '@injectivelabs/sdk-ts'

type TokenStoreState = {
  supplyMap: Record<string, string>
  tokenUsdPriceMap: Record<string, number>
  chainDenomDecimalsMap: Record<string, number>
  chainDenomMinNotionalMap: Record<string, number>
  unknownTokens: TokenStatic[]
}

const initialStateFactory = (): TokenStoreState => ({
  unknownTokens: [],
  supplyMap: {},
  tokenUsdPriceMap: {},
  chainDenomDecimalsMap: {},
  chainDenomMinNotionalMap: {}
})

export const useTokenStore = defineStore('token', {
  state: (): TokenStoreState => initialStateFactory(),
  getters: {
    tokens: () => Object.values(tokenStaticFactory.denomVerifiedMap),

    minNotionalTokens: (state) =>
      Object.keys(state.chainDenomMinNotionalMap)
        .map((denom) => tokenStaticFactory.toToken(denom))
        .filter((token) => token) as TokenStatic[],

    tokenByDenomOrSymbol:
      (state) =>
      (denomOrSymbol: string): TokenStatic | undefined => {
        if (!denomOrSymbol) {
          return
        }

        return (
          tokenStaticFactory.toToken(denomOrSymbol) ||
          state.unknownTokens.find(
            (token) => token.denom.toLowerCase() === denomOrSymbol.toLowerCase()
          )
        )
      },

    tokenUsdPrice:
      (state) =>
      ({ denom, coinGeckoId }: { denom: string; coinGeckoId: string }) => {
        return (
          state.tokenUsdPriceMap[denom] ||
          state.tokenUsdPriceMap[denom.toLowerCase()] ||
          state.tokenUsdPriceMap[coinGeckoId.toLowerCase()] ||
          0
        )
      }
  },
  actions: {
    async fetchSupply() {
      const tokenStore = useTokenStore()

      const { supply } = await tokenCacheApi.fetchTotalSupply()

      const unKnownDenoms: string[] = []
      const supplyMap = supply.reduce(
        (list, coin) => {
          const token = tokenStaticFactory.toToken(coin.denom)

          if (!token) {
            unKnownDenoms.push(coin.denom)
          }

          list[coin.denom] = coin.amount

          return list
        },
        {} as Record<string, string>
      )

      tokenStore.supplyMap = supplyMap
    },

    async getTokensUsdPriceMap() {
      const tokenStore = useTokenStore()

      tokenStore.tokenUsdPriceMap =
        await tokenPriceService.fetchUsdTokensPrice()
    },

    async fetchDenomDecimals() {
      const tokenStore = useTokenStore()

      const data = await exchangeApi.fetchDenomDecimals()

      tokenStore.chainDenomDecimalsMap = data.reduce(
        (list, item) => ({ ...list, [item.denom]: item.decimals }),
        {}
      )
    },

    async fetchDenomMinNotionals() {
      const tokenStore = useTokenStore()
      const data = await exchangeApi.fetchDenomMinNotionals()

      tokenStore.chainDenomMinNotionalMap = data.reduce(
        (list, item) => ({ ...list, [item.denom]: item.minNotional }),
        {}
      )
    },

    reset() {
      useTokenStore().$reset()
    }
  }
})
