
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91auction_93diYGM8lD3NMeta } from "/home/runner/work/injective-hub/injective-hub/pages/auction/[auction].vue?macro=true";
import { default as indexN71rcVSq5DMeta } from "/home/runner/work/injective-hub/injective-hub/pages/auction/index.vue?macro=true";
import { default as auctionrjCkk2HbHiMeta } from "/home/runner/work/injective-hub/injective-hub/pages/auction.vue?macro=true";
import { default as bridge7vCcfJMpegMeta } from "/home/runner/work/injective-hub/injective-hub/pages/bridge.vue?macro=true";
import { default as envlljJMAHA51Meta } from "/home/runner/work/injective-hub/injective-hub/pages/env.vue?macro=true";
import { default as external_45linkMo0rZHFkiyMeta } from "/home/runner/work/injective-hub/injective-hub/pages/external-link.vue?macro=true";
import { default as featured_45dappsxoS5BG13OJMeta } from "/home/runner/work/injective-hub/injective-hub/pages/featured-dapps.vue?macro=true";
import { default as governancewzO1H0tgnAMeta } from "/home/runner/work/injective-hub/injective-hub/pages/governance.vue?macro=true";
import { default as indexZqF1VbDmiUMeta } from "/home/runner/work/injective-hub/injective-hub/pages/index.vue?macro=true";
import { default as maintenance2vnBd8a3syMeta } from "/home/runner/work/injective-hub/injective-hub/pages/maintenance.vue?macro=true";
import { default as _91proposal_93rLBfdLQYJ7Meta } from "/home/runner/work/injective-hub/injective-hub/pages/proposal/[proposal].vue?macro=true";
import { default as _91_91type_93_93o0ZVPKpgmLMeta } from "/home/runner/work/injective-hub/injective-hub/pages/proposal/create/[[type]].vue?macro=true";
import { default as index6aDoUBgrK3Meta } from "/home/runner/work/injective-hub/injective-hub/pages/stake/index.vue?macro=true";
import { default as my_45staking6PsUFMKUFLMeta } from "/home/runner/work/injective-hub/injective-hub/pages/stake/my-staking.vue?macro=true";
import { default as stake4mO2ooWkC4Meta } from "/home/runner/work/injective-hub/injective-hub/pages/stake.vue?macro=true";
import { default as token_45factoryS9MZkVDg6EMeta } from "/home/runner/work/injective-hub/injective-hub/pages/token-factory.vue?macro=true";
import { default as _91validator_93PLwYToDdt0Meta } from "/home/runner/work/injective-hub/injective-hub/pages/validators/[validator].vue?macro=true";
import { default as cw20_45tokensiEnxQJgOrDMeta } from "/home/runner/work/injective-hub/injective-hub/pages/wallet/cw20-tokens.vue?macro=true";
import { default as indexuxDqW15gMjMeta } from "/home/runner/work/injective-hub/injective-hub/pages/wallet/index.vue?macro=true";
import { default as transactionsCXkpBE6nw3Meta } from "/home/runner/work/injective-hub/injective-hub/pages/wallet/transactions.vue?macro=true";
import { default as walletmXBmMXqIc2Meta } from "/home/runner/work/injective-hub/injective-hub/pages/wallet.vue?macro=true";
export default [
  {
    name: auctionrjCkk2HbHiMeta?.name,
    path: "/auction",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/auction.vue"),
    children: [
  {
    name: "auction-auction",
    path: ":auction()",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/auction/[auction].vue")
  },
  {
    name: "auction",
    path: "",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/auction/index.vue")
  }
]
  },
  {
    name: "bridge",
    path: "/bridge",
    meta: bridge7vCcfJMpegMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/bridge.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envlljJMAHA51Meta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/env.vue")
  },
  {
    name: "external-link",
    path: "/external-link",
    meta: external_45linkMo0rZHFkiyMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/external-link.vue")
  },
  {
    name: "featured-dapps",
    path: "/featured-dapps",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/featured-dapps.vue")
  },
  {
    name: "governance",
    path: "/governance",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/governance.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenance2vnBd8a3syMeta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/maintenance.vue")
  },
  {
    name: "proposal-proposal",
    path: "/proposal/:proposal()",
    meta: _91proposal_93rLBfdLQYJ7Meta || {},
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/proposal/[proposal].vue")
  },
  {
    name: "proposal-create-type",
    path: "/proposal/create/:type?",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/proposal/create/[[type]].vue")
  },
  {
    name: stake4mO2ooWkC4Meta?.name,
    path: "/stake",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/stake.vue"),
    children: [
  {
    name: "stake",
    path: "",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/stake/index.vue")
  },
  {
    name: "stake-my-staking",
    path: "my-staking",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/stake/my-staking.vue")
  }
]
  },
  {
    name: "token-factory",
    path: "/token-factory",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/token-factory.vue")
  },
  {
    name: "validators-validator",
    path: "/validators/:validator()",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/validators/[validator].vue")
  },
  {
    name: walletmXBmMXqIc2Meta?.name,
    path: "/wallet",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/wallet.vue"),
    children: [
  {
    name: "wallet-cw20-tokens",
    path: "cw20-tokens",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/wallet/cw20-tokens.vue")
  },
  {
    name: "wallet",
    path: "",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/wallet/index.vue")
  },
  {
    name: "wallet-transactions",
    path: "transactions",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/wallet/transactions.vue")
  }
]
  },
  {
    name: "proposals-proposal",
    path: "/proposals/:proposal?",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/governance.vue")
  },
  {
    name: "proposals",
    path: "/proposals",
    component: () => import("/home/runner/work/injective-hub/injective-hub/pages/governance.vue")
  }
]