export default {
  terms: {
    title: 'Terms of Service',
    disclaimer: 'Disclaimer',
    privacyPolicy: 'Privacy Policy',
    termsAndCondition: 'Terms and Conditions',
    disclaimerNote:
      'By connecting a wallet, you agree to the following terms of service:'
  }
}
