<script setup lang="ts">
import { JSON_POLL_INTERVAL } from '@shared/utils/constant'
import {
  validators,
  spotMarkets,
  derivativeMarkets,
  restrictedCountries,
  blacklistedAddresses
} from '@/app/json'

const jsonStore = useSharedJsonStore()

const emit = defineEmits<{
  'on:loaded': []
}>()

onMounted(() => {
  mountCachedJson()

  pollJson().finally(() => {
    emit('on:loaded')
  })
})

function mountCachedJson() {
  jsonStore.validators = validators
  jsonStore.spotMarkets = spotMarkets as any
  jsonStore.restrictedCountries = restrictedCountries
  jsonStore.blacklistedAddresses = blacklistedAddresses
  jsonStore.derivativeMarkets = derivativeMarkets as any
}

function pollJson() {
  return Promise.all([
    jsonStore.fetchToken(),
    jsonStore.fetchValidators(),
    jsonStore.fetchSpotMarkets(),
    jsonStore.fetchDerivativeMarkets(),
    jsonStore.fetchRestrictedCountries(),
    jsonStore.fetchBlacklistedAddresses()
  ])
}

useIntervalFn(pollJson, JSON_POLL_INTERVAL)
</script>

<template>
  <div />
</template>
