import { defineStore } from 'pinia'
import { currencies } from '@/app/region/currencies'
import { Modal, Theme, SingleCurrency } from '@/types'

interface UserBasedState {
  modalsViewed: Modal[]
  termsAccepted: boolean
}

type AppStoreState = {
  theme: Theme
  devMode: boolean
  currency: SingleCurrency
  selectedLanguage: string

  // User settings
  userState: UserBasedState
}

const initialStateFactory = (): AppStoreState => ({
  devMode: false,
  theme: Theme.Dark,
  selectedLanguage: '',
  currency: currencies.USD,

  userState: {
    modalsViewed: [],
    termsAccepted: false
  }
})

export const useAppStore = defineStore('app', {
  state: (): AppStoreState => initialStateFactory(),
  actions: {
    setUserState(userState: Object) {
      const appStore = useAppStore()

      appStore.$patch({ userState })
    },
    setSelectedLanguage(selectedLocale: string) {
      const appStore = useAppStore()

      appStore.$patch({ selectedLanguage: selectedLocale })
    }
  }
})
